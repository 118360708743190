<template>
  <div class="ManagePaymentMethod">

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CModal
      :show.sync="isAddingPaymentMethod"
      :no-close-on-backdrop="true"
      title="Erreur lors de la souscription"
      size="lg"
      color="dark"
      :closeOnBackdrop="false"
    >
      <CInput
        id="cardholder-name"
        label="Nom du titulaire de la carte"
        autocomplete="eclerk-no-autocomplete-input"
        v-model="newCardHolderName" type="text"
        maxlength="200"
        placeholder="Nom du titulaire"
        @input="$v.newCardHolderName.$touch()"
        :isValid="$v.newCardHolderName.$dirty ? !$v.newCardHolderName.$error : null"
        invalid-feedback="Le nom doit comporter entre 2 et 200 caractères"
      >
      </CInput>
      <!-- placeholder for Elements -->
      <!--<form id="setup-form" :data-secret="clientSecret">-->
      <div id="card-element"></div>
      <small id="card-errors" class="text-danger"></small>
      <CRow class="mt-4">
        <CCol class="text-center">
          <CButton
            color="outline-primary"
            shape="pill" block class="px-4"
            @click="confirmCardSetup"
            :disabled="$v.newCardHolderName.$invalid || isConfirmCardSetup">
              Ajouter votre carte
          </CButton>
        </CCol>
      </CRow>
      <!--</form>-->
      <template #footer>
        <CButton @click="isAddingPaymentMethod = false" color="dark">Annuler</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="isPaymentMethodAdded"
      :no-close-on-backdrop="true"
      title="Moyen de paiement ajouté"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre nouvelle carte bancaire a bien été ajoutée 💳
      </p>
      <p>
        Si vous le souhaitez, vous pouvez définir ce moyen de paiement par défaut pour
        vos futures transactions.
      </p>

      <template #footer>
        <CButton @click="isPaymentMethodAdded = false" color="dark">Fermer</CButton>
      </template>
    </CModal>


    <CRow>
      <CCol sm="6">
        <h1> Vos moyens de paiement </h1>
      </CCol>
      <CCol class="text-right align-self-center" sm="6">
        <CButton
          @click="createSetupIntent"
          size="sm"
          shape="pill"
          color="success">
            <CIcon name="cil-plus"/> Ajouter une nouvelle carte
        </CButton>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <CDataTable
          headVariant="light"
          :items="paymentMethodsRender"
          :fields="paymentMethodsRenderFields"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
        >
        <template #brand="{item}">
          <td class="text-center">
            <span style="text-transform: capitalize">{{item.brand}}</span>
          </td>
        </template>

        <template #last4="{item}">
          <td class="text-center">
            <span>***-{{item.last4}}</span>
          </td>
        </template>

        <template #exp="{item}">
          <td class="text-center" v-if="item.is_expired">
            <span class="text-danger"> Expirée {{$dayjs(item.exp).format('MMMM YYYY')}}</span>
          </td>
          <td class="text-center" v-else>
            <span> {{$dayjs(item.exp).format('MMMM YYYY')}}</span>
          </td>
        </template>

        <template #is_default_payment="{item}">
          <td class="text-center" v-if="item.id === customer.invoice_settings.default_payment_method">
            <CBadge shape="pill" color="success">Oui</CBadge>
          </td>
          <td class="text-center" v-else>
            <CBadge shape="pill" color="secondary">Non</CBadge>
          </td>
        </template>
        <template #actions="{item}">
          <td v-if="item.id != customer.invoice_settings.default_payment_method">
            <CDropdown
             color="outline-dark"
             toggler-text="Action"
             size="sm"
             >
              <CDropdownItem v-if="!item.is_expired" @click="setPaymentMethodAsDefault(item.id)">
                Définir comme moyen de paiement par défaut
              </CDropdownItem>
              <CDropdownItem @click="detachPaymentMethod(item.id)">
                Supprimer
              </CDropdownItem>
            </CDropdown>
          </td>
          <td v-else>
          </td>

        </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import { stripePublishableKey } from '@/variables/localVariables'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { APIBossConnected } from '@/api/APIBossConnected'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiBossConnected = new APIBossConnected()

var stripe = Stripe(stripePublishableKey), elements = stripe.elements(), cardElement = elements.create('card');


export default {
  name: 'ManagePaymentMethod',
  components: {
    Loading,
  },
  mixins: [
    validationMixin,
  ],
  data: function () {
    return {

      // ---- PAYMENT METHODS ---------
      isPaymentMethodsLoading: false,
      payment_methods: [],
      paymentMethodsRender: [],
      paymentMethodsRenderFields: [
        { key: "brand", label: "Type", tdClass: 'ui-helper-center', sortable: true},
        { key: "last4", label: "Numéro", tdClass: 'ui-helper-center', sortable: true},
        { key: "exp", label: "Date d'expiration", tdClass: 'ui-helper-center'},
        { key: "is_default_payment", label: "Paiement par défaut", tdClass: 'ui-helper-center'},
        { key: "actions", label: "", tdClass: 'ui-helper-center'},
      ],

      customer: {
        invoice_settings: {
          default_payment_method: ''
        }
      },

      isSetupIntentCreated: false,
      clientSecret: '',

      isAddingPaymentMethod: false,
      newCardHolderName: '',

      isConfirmCardSetup: false,
      isPaymentMethodAdded: false,

      isSettingPaymentMethodAsDefault: false,

      isDetachingPaymentMethod: false

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isPaymentMethodsLoading || this.isSetupIntentCreated || this.isConfirmCardSetup || this.isSettingPaymentMethodAsDefault || this.isDetachingPaymentMethod) {
        return true
      }
      return false
    },
  },
  mounted: function () {
    cardElement.mount('#card-element');
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.listPaymentMethod()
  },

  validations: {
    newCardHolderName: {
      required,
      maxLength: maxLength(199),
      minLength: minLength(2)
    }
  },

  watch: {
    payment_methods: function (newPaymentMethods) {
      if (newPaymentMethods.length == 0) {
        this.paymentMethodsRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newPaymentMethods.length; i++) {
          var is_expired = false
          var expired_date = new Date(newPaymentMethods[i].card.exp_year, newPaymentMethods[i].card.exp_month - 1, 1)
          var today = new Date()
          if (today >= expired_date) {
            is_expired = true
          }

          if (newPaymentMethods[i].card.exp_year)
          final_array.push(
            {
              'id': newPaymentMethods[i].id,
              'brand': newPaymentMethods[i].card.brand,
              'last4': newPaymentMethods[i].card.last4,
              'exp': expired_date,
              'is_expired': is_expired
            }
          )
        }
        this.paymentMethodsRender = final_array
      }
    },

  },

  methods: {
    showCardError(event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    },

    // ------------- Stripe functions -----------
    listPaymentMethod() {
      this.isPaymentMethodsLoading = true
      return apiBossConnected.listPaymentMethod(this.token)
        // If the card is declined, display an error to the user.
        .then((result) => {
          this.customer = result.data.customer
          this.payment_methods = result.data.payment_methods.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isPaymentMethodsLoading = false
        })
    },


    // --------- ADD PAYMENT METHODS ------------
    createSetupIntent() {
      this.isSetupIntentCreated = true
      return apiBossConnected.createSetupIntent(this.token)
        // If the card is declined, display an error to the user.
        .then((result) => {
          this.clientSecret = result.data.client_secret
          this.isAddingPaymentMethod = true
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isSetupIntentCreated = false
        })
    },

    confirmCardSetup () {
      this.isConfirmCardSetup = true

      stripe.confirmCardSetup(
        this.clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: this.newCardHolderName
            }
          }
        }
      )
      .then((result) => {
        if (result.error) {
          this.showCardError(result)
        }
        else {
          this.isAddingPaymentMethod = false
          this.isPaymentMethodAdded = true
          this.listPaymentMethod()
        }
      })
      .finally(() => {
        this.isConfirmCardSetup = false
      })
    },

    setPaymentMethodAsDefault(payment_method_id) {
      this.isSettingPaymentMethodAsDefault = true
      return apiBossConnected.setPaymentMethodAsDefault(this.token, payment_method_id)
        .then((result) => {
          this.listPaymentMethod()
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isSettingPaymentMethodAsDefault = false
        })
    },

    detachPaymentMethod(payment_method_id) {
      this.isDetachingPaymentMethod = true
      return apiBossConnected.detachPaymentMethod(this.token, payment_method_id)
        .then((result) => {
          this.listPaymentMethod()
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isDetachingPaymentMethod = false
        })
    },
  }
}
</script>
